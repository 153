import React, { Component, useState, useEffect, useRef } from "react"
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  Checkbox,
  AutoComplete,
  InputNumber,
  message,
  Divider,
} from "antd"
import AppLayout from "../../components/layout"
import "../car-spotted/cardropoff.css"
import api from "../../api"
import {
  deleteByAssetTxnsId,
  getClosedOutList,
} from "../../actions/myYardAction"
import { useDispatch } from "react-redux"
import { getEquipmentPickup } from "../../actions/dashboardAction"
import { navigate } from "gatsby"
const CarPickUp = props => {
  const dispatch = useDispatch()
  const { checkedinEquipment, equipmentCheckout, mailWithOutAttachment } = api
  const newProps = props?.location?.state
  const pagePath = newProps?.newData?.title?.split(" ")[1]
  const [equipmentList, setEquipmentList] = useState([])
  const [assetTxnId, setAssetTxnId] = useState(null)
  const [yardName, setYardName] = useState(null)
  const [clientDetails, setClientDetails] = useState(null)
  const [btnDisable, setBtnDisable] = useState(false)
  const [reviewCarFile, setReviewCarFile] = useState(false)
  const [image, setImage] = useState(null)
  const [
    confirmPhotoPackageIsComplete,
    setConfirmPhotoPackageIsComplete,
  ] = useState(false)
  const [form] = Form.useForm()

  const onChangeCarDrpOff = value => {
    const element = equipmentList?.find(ele => ele?.value == value)
    setAssetTxnId(element?.asset_txns_id)

    setYardName(element?.yardName)
    form.setFieldsValue({
      customer: element?.customerName,
      washout: element?.ticket_ref_id,
      equipmentType: element?.equipmentType,
      trackNumber: element?.trackNumber,
    })
  }

  const onChangeChackBox = e => {
    console.log(`checked = ${e.target.checked}`)
  }
  useEffect(() => {
    const tabParam = JSON.parse(localStorage.getItem("data"))
    setClientDetails({
      clientName: tabParam.first_name,
      clientCompany: tabParam.clientCompany,
      email: tabParam.email,
      phone: tabParam.mobile,
    })
  }, [])
  const propsUpload = {
    name: "file",
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === "done") {
        console.log(info?.file?.response)
        setImage(info?.file?.response?.data?.filepath)
        message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
  }
  const onSearchCarDrpOff = value => {
    console.log("search:", value)
  }
  const filterOptionCarDrpOff = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
  const onFinishCarDropOff = values => {
    const data = {
      asset_txns_id: assetTxnId,
      details: [
        { key: "pickup_carrier", value: values.truck_carrier },
        { key: "pickup_driver_name", value: values.driver },
        { key: "pickup_email", value: values.email },
        { key: "pickup_mobile", value: values.number },
        { key: "reviewCarFile", value: reviewCarFile },
        { key: "washout", value: values.washout },

        {
          key: "confirmPhotoPackageIsComplete",
          value: confirmPhotoPackageIsComplete,
        },
        {
          key: "sealNumberRecorded",
          value: values?.sealNumberRecorded,
        },
        {
          key: "pickup_image",
          value: image,
        },
      ],
    }
    equipmentCheckout(data)
      .then(async res => {
        if (res.data.success === true) {
          dispatch(getClosedOutList(0, ""))
          dispatch(deleteByAssetTxnsId("assetList", assetTxnId))
          dispatch(deleteByAssetTxnsId("inYardList", assetTxnId))
          dispatch(getEquipmentPickup())
          setBtnDisable(false)
          form.resetFields()

          if (values.email) {
            await mailWithOutAttachment({
              to: values.email,
              from: "info@washtrax.com",
              subject: "EQUIPMENT PICKED UP",
              client_manager: clientDetails.clientName,
              client_name: clientDetails.clientCompany,
              client_manager_email: clientDetails.email,
              client_manager_phone: clientDetails.phone,
              type: "pickup",
              equipment_no: values.equipment_number,
              yardAddress: yardName,
            })
          }
          navigate("/dashboard")
        } else {
          setBtnDisable(false)
        }
      })
      .catch(err => {
        setBtnDisable(false)
      })
  }
  const onFinishFailedCarDropOff = errorInfo => {
    console.log("Failed:", errorInfo)
  }
  useEffect(() => {
    checkedinEquipment()
      .then(res => {
        const _data = res.data.data
        const eqNo = []

        _data &&
          _data.map(data => {
            eqNo.push({
              value: data.equipment_no,
              label: data.equipment_no,
              customerName: data.customer_details.name,
              clients_cust_id: data.customer_details.clients_cust_id,
              equipmentType: data.equipment_type_details.param_name,
              ticket_ref_id: data.ticket_details?.ticket_ref_id,
              asset_txns_id: data.asset_txns.asset_txns_id,
              yardName: data.asset_txns.yard_details.name,
              trackNumber: data?.asset_txns?.asset_txns_details?.field_value,
            })
          })
        setEquipmentList(eqNo)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])
  const token = typeof window !== "undefined" && localStorage.getItem("token")
  const { GATSBY_BACKEND_URL } = process.env
  return (
    <AppLayout>
      <div className="cardropoff_section">
        <div className="cardrop_header">
          <div className="cardrop_head_1">{"Car Release"}</div>
          {/** <div className="cardrop_head_2">
            <span>{pagePath}</span>
            {">" + " " + newProps?.title}
          </div> */}
        </div>
        <Form
          className="car_drop_form"
          name="basic"
          onFinish={onFinishCarDropOff}
          onFinishFailed={onFinishFailedCarDropOff}
          autoComplete="off"
          layout={"vertical"}
          form={form}
          style={{
            maxWidth: "100%",
          }}
        >
          <Form.Item
            className="car_form_item"
            label="Car No."
            name="equipment_number"
            rules={[
              {
                required: true,
                message: "Please select Equipment Number!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Enter Car No"
              optionFilterProp="children"
              onChange={onChangeCarDrpOff}
              // onSearch={onSearchCarDrpOff}
              filterOption={filterOptionCarDrpOff}
              options={equipmentList}
            />
          </Form.Item>
          <Form.Item
            className="car_form_item"
            label="Car Type"
            name="equipmentType"
          >
            <Input
              disabled
              placeholder="Car Type"
              style={{ background: "#0000000D", border: "none" }}
            />
          </Form.Item>
          <Form.Item className="car_form_item" label="Track" name="trackNumber">
            <Input
              placeholder="Enter Track No"
              disabled
              style={{ background: "#0000000D", border: "none" }}
            />
          </Form.Item>
          <div className="car_pick_up_two_ch">
            <Form.Item
              className="car_form_item"
              label="Customer Name"
              name="customer"
            >
              <Input
                disabled
                placeholder="Enter Customer Name"
                style={{ background: "#0000000D", border: "none" }}
              />
            </Form.Item>
            <Form.Item
              className="car_form_item"
              label="Washout Ticket"
              name="washout"
            >
              <Input
                placeholder="Enter Washout Ticket"
                disabled
                style={{ background: "#0000000D", border: "none" }}
              />
            </Form.Item>
          </div>
          <div className="car_pick_up_checkbox">
            <div>
              <p>Review Car File</p>
              <Checkbox
                checked={reviewCarFile}
                onChange={() => setReviewCarFile(!reviewCarFile)}
              ></Checkbox>
            </div>
            <div>
              <p>Confirm Photo Package is Complete</p>
              <Checkbox
                checked={confirmPhotoPackageIsComplete}
                onChange={value =>
                  setConfirmPhotoPackageIsComplete(
                    !confirmPhotoPackageIsComplete
                  )
                }
              ></Checkbox>
            </div>
          </div>
          <Form.Item
            className="car_form_item"
            label="Seal Numbers Recorded"
            name="sealNumberRecorded"
          >
            <Input placeholder="Enter Seal Number" />
          </Form.Item>

          <Form.Item
            className="car_form_item"
            label="Attach supporting documents"
            name="Attach supporting documents "
          >
            <div className="car_pick_upload">
              <Upload
                action={`https://backend-demo.washtrax.com/image/upload`}
                headers={{
                  Authorization: `Bearer ${token}`,
                }}
                {...propsUpload}
                className="car_pick_upload_btn"
              >
                <Button className="car_pick_upload_btnc">File...</Button>
              </Upload>
              <div className="car_pick_upload_text">browse</div>
            </div>
          </Form.Item>

          <div className="cardrop_carInfo">
            <p>Provide release confirmation</p>
            <div></div>
          </div>
          <Form.Item
            className="car_form_item"
            label="Contact Person Name"
            name="driver"
          >
            <Input placeholder="Enter Contact Person Name" />
          </Form.Item>
          <Form.Item className="car_form_item" label="Phone No." name="number">
            <Input placeholder="Enter Phone No." />
          </Form.Item>
          <Form.Item className="car_form_item" label="Email Id" name="email">
            <Input placeholder="Enter Emai Id" />
          </Form.Item>

          <Form.Item className="car_drop_submit">
            <Button type="primary" htmlType="submit" disabled={btnDisable}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AppLayout>
  )
}

export default CarPickUp
